import Vue from 'vue'
import axios from '@axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'
import { camperStudent } from '@/helpers/instanceNamingConvention'
import { DEFAULT_PER_PAGE, PER_PAGE_OPTIONS } from '@/constants/base'
import setPerPage from '@/helpers/setPerPage'

// import store from '@/store'

export default {
  namespaced: true,
  state: {
    tableColumns: [
      { key: 'selected', label: 'selected', selected: true },
      { key: 'name', label: 'Name', sortable: false },
      { key: 'group_names', label: 'Groups', sortable: false },
      { key: 'tags', label: 'tags', sortable: false },
    ],
    tableItems: [],
    perPage: Number(localStorage.getItem('tagViewPerPage')) || DEFAULT_PER_PAGE,
    totalItems: 0,
    currentPage: 1,
    perPageOptions: PER_PAGE_OPTIONS,
    sortBy: 'created_at',
    searchQuery: '',
    dataMeta: {
      from: 0,
      to: 0,
      of: 0,
    },
    isSortDirDesc: true,
    selectedApplication: null,
    selectedTags: [],
    filterDataGroups: [],
    allGroupsList: [],
    tagStatistics: [],
    markedElements: [],
    isLoading: false,
  },
  getters: {
    getTableColumns: state => state.tableColumns,
    getTableItems: state => state.tableItems,
    getPerPage: state => state.perPage,
    getTotalItems: state => state.totalItems,
    getCurrentPage: state => state.currentPage,
    getPerPageOptions: state => state.perPageOptions,
    getSortBy: state => state.sortBy,
    getSearchQuery: state => state.searchQuery,
    getIsSortDirDesc: state => state.isSortDirDesc,
    getDataMeta: state => state.dataMeta,
    getSelectedApplication: state => state.selectedApplication,
    getActionType: state => state.actionType,
    getSelectedTags: state => state.selectedTags,
    getTagStatistics: state => state.tagStatistics,
    getFilterDataGroups: state => state.filterDataGroups,
    getAllGroupsList: state => state.allGroupsList,
    getApplicationsList: state => state.applicationsList,
    getMarkedElements: state => state.markedElements,
    getIsLoading: state => state.isLoading,
  },
  mutations: {
    SET_PER_PAGE(state, val) {
      localStorage.setItem('tagViewPerPage', val.toString())
      state.perPage = val
    },
    SET_TOTAL_ITEMS(state, val) {
      state.totalItems = val
    },
    SET_TABLE_ITEMS(state, val) {
      state.tableItems = val
    },
    SET_CURRENT_PAGE(state, val) {
      state.currentPage = val
    },
    SET_SORT_BY(state, val) {
      state.sortBy = val
    },
    SET_SEARCH_QUERY(state, val) {
      state.searchQuery = val
    },
    SET_IS_SORT_DESC(state, val) {
      state.isSortDirDesc = val
    },
    SET_DATA_META(state, val) {
      state.dataMeta = val
    },
    SET_FILTER_PARAMS(state) {
      const query = { ...router.currentRoute.query }
      const defaultPerPage = localStorage.getItem('tagViewPerPage')
      state.currentPage = Number(query.currentPage) || 1
      state.perPage = setPerPage(query.perPage, defaultPerPage, DEFAULT_PER_PAGE)
      state.searchQuery = query.searchQuery || ''
      state.selectedTags = query.selectedTags || []
      state.filterDataGroups = query.filterDataGroups || []
    },
    SET_SELECTED_APPLICATION: (state, val) => {
      state.selectedApplication = val
    },
    RESET_SELECTED_APPLICATION: state => {
      state.selectedApplication = null
    },
    SET_SELECTED_TAGS: (state, val) => {
      state.selectedTags = val
    },
    SET_TAG_STATISTICS: (state, val) => {
      state.tagStatistics = val
    },
    SET_FILTER_DATA_GROUPS: (state, val) => {
      state.filterDataGroups = val
    },
    SET_ALL_GROUPS_LIST: (state, val) => {
      state.allGroupsList = val
    },
    SET_MARKED_ELEMENTS: (state, val) => {
      state.markedElements = val
    },
    ADD_APPLICATION_TO_LIST: (state, val) => {
      state.applicationsList.push(val)
    },
    SET_IS_LOADING: (state, val) => {
      state.isLoading = val
    },
    RESET_STATE: state => {
      state.applicationId = ''
      state.selectedTags = []
      state.tagStatistics = []
      state.filterDataGroups = []
      state.allGroupsList = []
      state.applicationsList = []
      state.isLoading = false
    },
  },
  actions: {
    async fetchTagViewList({ state, commit, rootGetters }) {
      try {
        commit('SET_IS_LOADING', true)

        const queryParams = {
          perPage: state.perPage,
          page: state.currentPage || 1,
          sortBy: state.sortBy,
          sortDesc: state.isSortDirDesc,
          withoutDraft: true,
          userRole: camperStudent,
          programId: rootGetters['verticalMenu/getDefaultProgram'],
        }

        if (state.selectedTags.length) queryParams.tagId = state.selectedTags
        if (state.searchQuery) queryParams.userName = state.searchQuery
        if (state.filterDataGroups) queryParams.group_id = state.filterDataGroups
        const response = await await axios.get('/auth/applications', {
          params: queryParams,
        })

        const {
          total, from, to,
        } = response.data.meta

        const dataMeta = {
          from,
          to,
          of: total,
        }

        commit('SET_TOTAL_ITEMS', total)
        commit('SET_DATA_META', dataMeta)
        commit('SET_TABLE_ITEMS', response.data.data)
        return response.data.data
      } catch (error) {
        Vue.$toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching applications' list",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        commit('SET_TABLE_ITEMS', [])
        return []
      } finally {
        commit('SET_IS_LOADING', false)
      }
    },
    async fetchTagStatistics({ commit }, { queryParams }) {
      try {
        const response = await axios.post('/auth/tags-statistics', queryParams)

        commit('SET_TAG_STATISTICS', response.data.data)
        return response
      } catch (error) {
        return error
      }
    },
    async fetchAllGroupsList({ commit }, queryParams) {
      try {
        const response = await axios.get('/auth/groups-for-select', {
          params: queryParams,
        })
        commit('SET_ALL_GROUPS_LIST', response.data.data)
        return response
      } catch (error) {
        return error
      }
    },
    async updateApplicationTags({ state, commit }) {
      try {
        const queryParams = {
          action: state.actionType,
          tag_ids: state.selectedTags,
        }
        commit('SET_IS_LOADING', true)

        const response = await axios.post(`/auth/applications/${state.applicationId}/tags`, queryParams)
        commit('ADD_APPLICATION_TO_LIST', response.data.data)
      } catch (error) {
        Vue.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error updating Application Tags',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      } finally {
        commit('RESET_APPLICATION_ID')
        commit('SET_IS_LOADING', false)
      }
    },
  },
}
